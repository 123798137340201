body {
    margin: 0;
    padding: 0;
    font-family: system-ui, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

div {
    box-sizing: border-box;
}


